import { Container } from '@components'
import { OurPolicy } from '@pages/components'
import React, { FC } from 'react'

interface IShippingPolicy {}

const ShippingPolicy: FC<IShippingPolicy> = () => {
  return (
    <Container>
      <div className={'banner-terms'}>
        <div className="banner-terms__title"> TourDay Golf</div>
        <div className="banner-terms__subtitle">Shipping Policy</div>
      </div>
      <OurPolicy.ShippingPolicyContent />
    </Container>
  )
}

export default ShippingPolicy
